<template>
  <el-container>
    <el-aside v-if="$route.path !== '/home/comfyui'" :width="isCollapse ? '50px' : '120px'" class="menu-box">
      <div class="menu-title flex-row flex-center">
        <img
          :src="getLogoUrl"
          class="logo-img"
          :class="{ collapsed: isCollapse }"
          alt=""
        />
      </div>
      <el-scrollbar class="page-scroll">
        <el-menu :default-openeds="['0', '1', '2']" :default-active="`${menuIndex}`" :collapse="isCollapse">
          <template v-for="(menu, index) in menuList" :key="index">
            <div v-if="menu.children" :index="`${index}`">
              <span class="submenu">{{ menu.name }}</span>
              <el-menu-item v-for="(item, subIndex) in menu.children" :title="item.name"
                @click="menuBtn(item, `${index}-${subIndex}`)" :key="subIndex" :index="`${index}-${subIndex}`">
                <component class="icon-tabbar" v-if="item.icon" :is="item.icon" />
                <img v-else :src="getImageUrl(item.img)" class="icon-tabbar"
                  :class="{ selected: `${index}-${subIndex}` === menuIndex }" alt="" />
                <template #title>{{ item.name }}</template>
              </el-menu-item>
            </div>
            <!-- <el-menu-item v-else :title="menu.name" @click="menuBtn(menu, index)" :index="index.toString()">
              <component class="icon-tabbar" v-if="menu.icon" :is="menu.icon" />
              <img v-else :src="getImageUrl(menu.img)" class="icon-tabbar" :class="{ 'selected': index === menuIndex }"
                alt="" />
              <template #title>{{ menu.name }}</template>
            </el-menu-item> -->
          </template>
        </el-menu>
        <el-row justify="center">
          <el-button @click="changeMenuCollapse" type="primary" :icon="isCollapse ? ArrowRight : ArrowLeft" circle />
        </el-row>
        <el-row justify="center" class="my-12">
          <!-- <div class="beian"> -->
            <span target="_blank" class="font-10 pointer" @click="jumpToGov">闽ICP备2021008169号-5</span>
          <!-- </div> -->
        </el-row>
      </el-scrollbar>
      <!-- <div @click="changeMenuCollapse" class="collapse pointer" :style="{ left: isCollapse ? '50px' : '120px' }">
        <el-icon size="12" color="#fff">
          <i-ep-arrow-right v-if="isCollapse" />
          <i-ep-arrow-left v-else />
        </el-icon>
      </div> -->
    </el-aside>
    <el-main class="right-box pl-6 pr-6 py-6">
      <navTop></navTop>
      <div class="mt-6">
        <router-view></router-view>
      </div>
    </el-main>
  </el-container>
</template>

<script lang="ts">
import { getImageUrl } from "@/utils/utils.js";
import { globalData } from "@/store/store.js";
import { mapActions, mapState } from "pinia";
import {
  HomeFilled,
  ChatDotRound,
  Message,
  PictureFilled,
  List,
  ArrowRight,
  ArrowLeft,
  Picture,
  Connection,
  CreditCard,
  Cpu,
  Clock,
  Operation,
} from "@element-plus/icons-vue";

export default {
  data() {
    return {
      menuList: [
        {
          name: "聊天",
          // url: '/home/home',
          children: [
            {
              name: "GPTS",
              url: "/home/home",
              icon: markRaw(HomeFilled),
            },
            {
              name: "机器人",
              url: "/home/robots",
              icon: markRaw(Cpu),
            },
            {
              name: "知识库",
              url: "/home/chatpdf",
              img: "pdf.svg",
            },
            {
              name: "我的提问",
              url: "/home/issureLog",
              icon: markRaw(ChatDotRound),
            },
          ],
        },
        {
          name: "绘图",
          children: [
            {
              name: "绘图应用",
              url: "/home/paint/applicationList",
              icon: markRaw(PictureFilled),
            },
            {
              name: "ComfyUI",
              url: "/home/comfyui",
              icon: markRaw(Operation),
            },
            {
              name: "美图广场",
              url: "/home/paint/paintList",
              icon: markRaw(Picture),
            },
            {
              name: "我的作品",
              url: "/home/paint/myPaint",
              icon: markRaw(Clock),
            },
          ],
        },
        {
          name: "我的",
          // url: '/home/home',
          children: [
            {
              name: "金币明细",
              url: "/home/coinLog",
              icon: markRaw(List),
            },
            {
              name: "快速充值",
              url: "/home/recharge",
              icon: markRaw(CreditCard),
            },
            {
              name: "个人中心",
              url: "/home/user",
              icon: markRaw(Connection),
            },
            {
              name: "发送反馈",
              url: "/home/feedback",
              icon: markRaw(Message),
            },
          ],
        },
        // {
        //   name: '虚拟数字人',
        //   url: '/home/virtualRobot',
        //   img: 'face.svg'
        // },
        // {
        //   name: '视频二创',
        //   url: '/home/shortVideo',
        //   icon: markRaw(VideoCameraFilled)
        // },
      ],
      getImageUrl,
      ArrowRight: markRaw(ArrowRight),
      ArrowLeft: markRaw(ArrowLeft),
    };
  },
  watch: {
    "$route.path"(newValue, oldValue) {
      let matchIndex = "";
      this.menuList.forEach((submenu, index) => {
        const subIndex = submenu.children.findIndex((menu) =>
          newValue.includes(menu.url)
        );
        if (subIndex !== -1) {
          matchIndex = `${index}-${subIndex}`;
        }
      });
      if (matchIndex) {
        this.setMenuIndex(matchIndex);
      }
    },
  },
  computed: {
    ...mapState(globalData, ["isCollapse", "menuIndex", 'getLogoUrl', "token"]),
  },
  methods: {
    ...mapActions(globalData, ["changeMenuCollapse", "setMenuIndex", "setLoginVisible"]),
    jumpToGov() {
      window.open('https://beian.miit.gov.cn', '_blank', 'noopener noreferrer')
    },
    menuBtn(item, index) {
      if (item.url === "/home/comfyui") {
        if(this.token) {
          window.open(item.url + "?t=" + Date.now(), "_blank");
        } else {
         this.setLoginVisible(true);
        }
      } else if (item.url) {
        this.$router.push({ path: item.url });
        this.setMenuIndex(index);
      }
    },
  },
  mounted() {
    // 窗口大小变化自动收缩左侧菜单
    window.onresize = () => {
      if (
        (window.innerWidth <= 800 && !this.isCollapse) ||
        (window.innerWidth > 800 && this.isCollapse)
      ) {
        this.changeMenuCollapse();
      }
    };
  },
  created() {
    // 根据url设置初始选中菜单
    let matchIndex = "";
    if (window.location.pathname === "/home/comfyui") {
      return;
    }
    this.menuList.forEach((submenu, index) => {
      const subIndex = submenu.children.findIndex((menu) =>
        window.location.pathname.includes(menu.url)
      );
      if (subIndex !== -1) {
        matchIndex = `${index}-${subIndex}`;
      }
    });
    if (matchIndex) {
      this.setMenuIndex(matchIndex);
    }
  },
};
</script>

<style scoped lang="scss">
.menu-box {
  padding-top: 20px;
  height: 100vh;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  /* 加过渡给侧边导航*/
  transition: width 0.25s;
  -webkit-transition: width 0.25s;
  -moz-transition: width 0.25s;
  -webkit-transition: width 0.25s;
  -o-transition: width 0.25s;

  &::-webkit-scrollbar {
    display: none;
  }

  .page-scroll {
    height: calc(100% - 60px);

    :deep .el-menu {

      .el-sub-menu__title,
      .el-menu-item {
        --el-menu-item-height: 42px;
        --el-menu-sub-item-height: 40px;
      }
    }

    .submenu {
      padding-left: 6px;
      color: var(--el-text-color-regular);
    }

    .collapse-icon {
      border: 1px solid var(--el-color-primary-light-8);
      border-radius: 50%;
    }
  }

  .page-scroll .el-scrollbar__wrap {
    overflow-x: hidden;
  }

  .menu-title {
    color: $theme-color;
    margin-bottom: 20px;
  }

  .collapse {
    position: fixed;
    background-color: rgba(#606266, 0.2);
    height: 46px;
    width: 12px;
    line-height: 46px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    top: 50vh;

    &:hover {
      background-color: rgba(#606266, 0.3);
    }
  }

  .logo-img {
    width: 75px;

    &.collapsed {
      width: 48px;
    }
  }

  .el-menu {
    border-right: none !important;
    /*加快侧边栏文字消失的速度*/
    transition: all 10ms;
  }

  --el-menu-base-level-padding: 12px !important;

  .el-menu-item {
    .icon-tabbar {
      width: 22px;
      height: 22px;
      margin-right: 8px;

      &.selected {
        filter: brightness(0) saturate(100%) invert(58%) sepia(58%) saturate(3621%) hue-rotate(190deg) brightness(102%) contrast(102%);
      }
    }
  }

  .el-menu--collapse {
    .icon-tabbar {
      margin-right: 0;
    }
  }
}

.right-box {
  height: 100vh;
  background-color: #fafafa;
}
</style>
