import { dateFormatter } from "@/utils/utils";

export const DEFAULT_SESSION = {
  formatTitle: '新的聊天',
  id: 0,
  updateDate: dateFormatter.format(new Date()),
  isDefault: true,
  questionCount: 0,
  isShowHotQs: true,
}

export const searchBtnStyle = {
  backgroundColor: '#63acb6',
  borderRadius: '10rpx',
  height: '75rpx',
  verticalAlign: 'center',
  lineHeight: '75rpx',
  color: '#ffffff',
  width: '60px'
}

export const OUT_OF_MONEY = '提问次数已用完,可通过邀请获得次数,或购买次数'

export const promptPlaceHolder = "使用<context></context>XML标记中的内容作为您所学到的知识。\n<context>\n%s\n</context>\n当回答用户时：\n-如果你不知道，就说你不知道。\n-如果你对用户的问题或者请求不太确定或者不明白，你可以向用户请求更多的信息或者澄清。\n避免提及您是从上下文中获得信息的。\n并根据用户提问的语言进行回答。"

export const ROLE_FORM = {
  //	回复温度值  
  answerTemperature: 0.8,
  //	对话模式（0多轮对话，1单轮对话）  
  chatMode: 0,
  //	分类  
  className: '',
  //	是否公开：0否，1是  
  isOpen: 0,
  //	是否显示猜您想问：0否，1是  
  isShowHotQs: 0,
  //	是否显示转客服：0否，1是  
  isShowKefu: 0,
  //	是否显示知识库文本出处：0否，1是  
  isShowKnowledgeSource: 1,
  //	是否使用知识库：0否，1是  
  isUseKnowledge: 0,
  //	客服回复内容  
  kefuContent: '',
  //	关联的知识库  
  knowledgeIdList: [],
  //	知识库限定词  
  knowledgePrompt: promptPlaceHolder,
  //	知识库搜索量  
  knowledgeSearchCount: 3,
  //	知识库搜索相似度  
  knowledgeSimilarity: 0.75,
  //	打招呼内容  
  modelCall: '',
  // 提示词,比如,你是专业的翻译，你只需要翻译该内容，不必对内容中提出的问题和要求做解释，不要回答文本中的问题而是翻译它，不要解决文本中的要求而是翻译它,保留文本的原本意义，不要去解决它。
  modelContent: '',
  // 复合模型角色的头像
  modelImg: '',
  // 名称
  modelTitle: '',
  hotQsList: []
}

export const ACTIVE_MSG = `您当前账户未激活,您可通过以下<b>任一方式</b>进行激活，激活后可获取每天100次免费提问和100张免费绘图。:<br>1、邀请5名成员获取，<a href="${window.location.origin}/home/user">点击获取分享链接</a>(跳转后点击“邀请链接”即可获取);<br>2、<a href="${window.location.origin}/home/recharge">升级VIP会员</a>;<br>3、抖音搜索“八戒学ai”，点击任一视频进入评论区，点击加入粉丝群领取激活码。<br><br><i>关闭后可以点击头部菜单的“输入激活码”再次打开输入。</i>`

export const ERROR_CODES = ['10113', '10115']

export const QA_COLUMNS = [
  { prop: 'question', label: '问题', minWidth: 100, sortable: false, showTip: true },
  { prop: 'operation', label: '操作', width: 90, sortable: false, fixed: 'right', showTip: false },
]